import 'promise-polyfill/src/polyfill'
import './components/includes-polyfill'
import '../styles/main.scss'
import axios from 'axios'
import $ from 'jquery'
import { resolve } from 'dns'
import { saveAs } from 'file-saver'

class WebcastPosterGenerator {
  constructor() {
    this.options = {
      currentStep: 1,
      maxSteps: 7,
      format: '-',
      date1: '-',
      date2: '-',
      time1: '-',
      time2: '-',
      name1: '',
      name2: '',
      name3: '',
      title1: '',
      title2: '',
      title3: '',
      modifyUrl: 'webcast/swissport-modify-pdf/',
      downloadUrl: 'webcast/swissport-download-pdf/',
      deleteUrl: 'webcast/swissport-sanitize-download/',
      homeUrl: 'https://swissport-townhall-poster.com/',
    }
   
    this.liveUrl = 'https://convert-utils.onrender.com/'
    this.devUrl = 'http://localhost:5000/'
    
    this.isDev = window.location.hostname == 'localhost'
    this.host = this.isDev ? this.devUrl : this.liveUrl

    this.queryString = window.location.search

    this.setSessionStorageDatetimeForPdfName()
    this.selectOnChange()
    this.inputChange()
    //this.setInputFilterNumber()
    this.clickOnDownload()

    window.onbeforeunload = (e) => {
      if (sessionStorage.getItem('rpdfname') != '') {
        const deleteUrl = `${this.host}${this.options.deleteUrl}?pdfname=${sessionStorage.getItem('rpdfname')}`
        axios.get(deleteUrl).then((response) => {
          if (response.data == 'done') {
            sessionStorage.clear()
          }
        })
      }
    }
  }


  inputChange() {
    let timeout = null
    $(document).on('keyup', 'input[type="text"]', (e) => {
      const $input = $(e.currentTarget)
      const type = $input.attr('data-type')
      const workingStep = parseInt($input.attr('data-step'))

      let modifyPdf = false;
      if (type === 'date') {
        if ( $input.val().length > 7 ) {
          $input.val($input.val.substr(0,7));
        }
        if ($input.val().length >= 0 || $input.val() === '') {   
          modifyPdf = true;
        }
      } else {
        if ($input.val().length >= 0) {
          modifyPdf = true;
        }
      }


      if (modifyPdf) {
        this.setLoadingScreen(1)
        this.setStatusLastStep(0)
        clearTimeout(timeout)
        timeout = setTimeout( () => {
          let value = $input.val()
          const name = $input.attr('name')

          if ( value.length > 0) {
            if ($input.attr('name').includes('name')) {
              value = value.toUpperCase()
            }
          } 
          this.setFormValue(name, value, workingStep)
          this.activateNextStep(workingStep)
        }, 500)
      }
  });
  }

  setInputFilterNumber() {
    $(document).on('keypress', 'input[name="date1"], input[name="date2"]', (input) => {
      const $input = $(input.currentTarget)
      if (input) {
          const value = $input.val() + String.fromCharCode(input.which);
          return /^[0-9\.]*$/.test(value);
      }
    })
  }

  getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  setSessionStorageDatetimeForPdfName() {
    const secret = `${this.getRandomInt(10000, 30000)}`
    sessionStorage.setItem('rpdfn', secret)
  }

  setFormValue(name, value, step) {
    this.options['currentStep'] = step
    this.options[name] = value

    this.loadPdf(step, name)
  }

  selectOnChange() {
    $(document).on('change', '.form select', (e) => {
      const $selectField = $(e.currentTarget)
      const name = $selectField.attr('name')
      const value = $selectField.val()
      const currentStep = parseInt($selectField.attr('data-step'))

      this.options[name] = value

      if ($selectField.attr('name') === 'format') {
        $('input[type="text"]').each((input) => {
          for (let i = this.options.maxSteps; i > 1; i--) {
            this.setActiveStepRing(i, 0)
            this.deactivateStep(i)
          }
        })
      }

      if (value === '-' || currentStep === 1) {
        // deactivate all future steps
        for (let i = this.options.maxSteps; i > currentStep; i--) {
          this.setActiveStepRing(i, 0)
          this.deactivateStep(i)
        }

        // cancel last step
        this.setStatusLastStep(0)
      } else {
        this.setActiveStepRing(currentStep, 1)
        this.activateNextStep(currentStep)
      }

      this.setFormValue(name, value, currentStep)
    })
  }

  clickOnDownload() {
    $(document).on('click', `.form__row[data-step="${this.options.maxSteps}"]`, (e) => {
      const $e = $(e.currentTarget)
      if ($e.attr('data-active') == 0) {
        e.preventDefault()
        return
      }

      const pdfName = `${sessionStorage.getItem('rpdfn')}_webcast_poster.pdf`
      const url = `${this.host}${this.options.downloadUrl}?pdfname=${pdfName}`
      const downloadPdfName = `Global_Webcast_Poster_${this.options.format === 'poster' ? 'DIN_A3' : 'US_Tabloid'}.pdf`
      saveAs.saveAs(url, downloadPdfName)
    })
  }

  loadPdf(step) {
    if (step == 1) {
      this.hidePreviewDiv(true)
      if (this.options.format != 0 && this.options.format != null) {
        this.activateNextStep(step)
      }
    } else {
      if (this.options.format != null && this.options.format != 0) {
        this.setLoadingScreen(1)
        const pdfName = `${sessionStorage.getItem('rpdfn')}_webcast_poster.pdf`
        const filter = `?secret=${sessionStorage.getItem('rpdfn')}&format=${this.options.format}&date1=${this.options.date1}&date2=${this.options.date2}&time1=${this.options.time1}&time2=${this.options.time2}&name1=${encodeURIComponent(this.options.name1)}&name2=${encodeURIComponent(this.options.name2)}&name3=${encodeURIComponent(this.options.name3)}&title1=${encodeURIComponent(this.options.title1)}&title2=${encodeURIComponent(this.options.title2)}&title3=${encodeURIComponent(this.options.title3)}`
        sessionStorage.setItem('rpdfname', `${sessionStorage.getItem('rpdfn')}_webcast_poster.pdf`)

        axios.get(`${this.host}${this.options.modifyUrl}${filter}`).then((response) => {
          if (response.data == 'Err') {
            this.hidePreviewDiv(true)
          } else {
            this.hidePreviewDiv()
            const imgSrc = this.isDev ? response.data.replace(this.liveUrl, this.devUrl) : response.data

            $('#image')
              .attr('src', imgSrc)
              .on('load', () => {
                this.setLoadingScreen(0)
              })

            this.activateNextStep(step)
            if (step == this.maxSteps) {
              this.setStatusLastStep(1)
            }
          }
        })
      } else {
        this.hidePreviewDiv(true)
      }
    }
  }

  hidePreviewDiv(hide = false) {
    const $previewInfoDiv = $('.preview__no-preview')
    const $previewDiv = $('.preview__file')
    const $previewDivPDFContainer = $('.preview__pdf')

    if (hide) {
      $previewDiv.hide()
      $previewInfoDiv.show()
      $previewDivPDFContainer.addClass('with-border')
    } else {
      $previewDiv.show()
      $previewInfoDiv.hide()
      $previewDivPDFContainer.removeClass('with-border')
    }
  }

  setStatusLastStep(status) {
    const $lastStep = $(`.form__row[data-step="7"]`)
    const $lastStepShadow = $(`.form__row[data-step="7"] .form__shadow`)
    const $beforeDivider = $(`.form__divider[data-step="6_7"]`)

    $lastStep.attr('data-active', status)
    $beforeDivider.attr('data-active', status)
    if (status == 0) {
      $lastStepShadow.show()
    } else {
      $lastStepShadow.hide()
    }
  }

  activateNextStep(currentStep) {
    const nextStep = parseInt(currentStep) + 1
    const $nextFormRow = $(`.form__row[data-step="${nextStep}"]`)
    const $nextDivider = $(`.form__divider[data-step="${currentStep}-${nextStep}"]`)
    const $currentFormRow = $(`.form__row[data-step="${currentStep}"]`)

    // Step 4 (name 3) is optional, if next Step is 4 - activate step 5 too
    if (nextStep === 3) {
      this.setActiveStepRing(3, 1)
      this.activateNextStep(3)
    }
    if (nextStep === 4 ) {
      this.setActiveStepRing(4, 1)
      this.activateNextStep(4)
    }

    if (nextStep === 6) {
      this.setActiveStepRing(6, 1)
      this.activateNextStep(6)
    }

    if (nextStep <= this.options.maxSteps) {
      $nextDivider.attr('data-active', 1)
      $nextFormRow.attr('data-active', 1)
      this.setActiveStepRing(currentStep, 1)
    }
  }

  deactivateStep(step) {
    this.options['currentStep'] = step
    const $currentFormRow = $(`.form__row[data-step="${step}"]`)
    const $previousDivider = $(`.form__divider[data-step="${step - 1}-${step}"]`)
    const name = $currentFormRow.find('select').attr('name')
    const $input = $currentFormRow.find('input');

    $input.each((key, input) => {
      this.options[$(input).attr('name')] = ''
      $(input).val('')
    })
    

    $currentFormRow.attr('data-active', 0)
    $currentFormRow.find('select').val('-')
    
    $previousDivider.attr('data-active', 0)
    
    this.options[name] = '-'

  }

  setActiveStepRing(step, status) {
    $(`.form__row[data-step="${step}"]`).find('.form__step').attr('data-active', status)
  }

  setLoadingScreen(status) {
    const $loadingField = $('.preview__loading')
    status == 0 ? $loadingField.removeClass('load') : $loadingField.addClass('load')
  }
}

window.onload = () => {
  new WebcastPosterGenerator()
}
